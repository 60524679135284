import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/homePage/index.vue')
    },
    /* 产品服务 */
    {
        path: '/product/cheer-maas',
        name: 'cheerocean',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/cheerMaaS/index.vue')
    },
    {
        path: '/product/cheer-vm',
        name: 'cheervm',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/cheerVM/index.vue')
    },
    /* 中间件行业解决方案 */
    {
        path: '/solution/finance',
        name: 'finance',
        component: () => import(/* webpackChunkName: "solution" */ '@/views/solution/finance/index.vue')
    },
    {
        path: '/solution/energy',
        name: 'energy',
        component: () => import(/* webpackChunkName: "solution" */ '@/views/solution/energy/index.vue')
    },
    {
        path: '/solution/government',
        name: 'government',
        component: () => import(/* webpackChunkName: "solution" */ '@/views/solution/government/index.vue')
    },
    /* 关于智迩 */
    {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import(/* webpackChunkName: "aboutus" */ '@/views/aboutCheer/aboutUs/index.vue')
    },
    {
        path: '/honor',
        name: 'honor',
        component: () => import(/* webpackChunkName: "honor" */ '@/views/aboutCheer/honor/index.vue')
    },
    {
        path: '/culture',
        name: 'culture',
        component: () => import(/* webpackChunkName: "culture" */ '@/views/aboutCheer/culture/index.vue')
    },
    {
        path: '/dynamic',
        name: 'dynamic',
        component: () => import(/* webpackChunkName: "dynamic" */ '@/views/aboutCheer/dynamic/index.vue')
    },
    /* 联系我们 */
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import(/* webpackChunkName: "contactus" */ '@/views/contactUs/index.vue')
    },
    /* 联系我们 */
    {
        path: '/joinus',
        name: 'joinus',
        component: () => import(/* webpackChunkName: "joinus" */ '@/views/joinUs/index.vue')
    },
    /* 经典案例 */
    {
        path: '/casePage',
        name: 'casePage',
        component: () => import(/* webpackChunkName: "casePage" */ '@/views/casePage/index.vue')
    },
    /* 404页面 */
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "about" */ '@/views/error/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
