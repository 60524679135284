// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n/* 自定义颜色进度条 */\n#nprogress .bar[data-v-7ba5bd90] {\n    background: #409eff !important;\n}\n/* 修改默认菜单文字大小 */\n.ant-menu[data-v-7ba5bd90] {\n    font-size: 18px;\n}\n/* 修改默认菜单 */\n.ant-menu-horizontal[data-v-7ba5bd90] {\n    border-bottom: 0;\n}\n/* 顶部导航栏 */\n.ant-layout-header[data-v-7ba5bd90] {\n    background: #ffffff;\n}\n/* logo图片 等比例缩放 */\n.logo[data-v-7ba5bd90] {\n    width: 120px;\n    height: 40px;\n    margin-top: 10px;\n    margin-left: 30px;\n    margin-right: 30px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 100% 100%;\n    cursor: pointer;\n    float: left;\n}\n.logo img[data-v-7ba5bd90] {\n    width: 100%;\n    height: 100%;\n}\n/* 顶部搜索框 */\n.seach[data-v-7ba5bd90] {\n    float: right;\n    margin-right: 3rem;\n}\n/* 底部导航栏 */\n.footer[data-v-7ba5bd90] {\n    background: #409eff;\n    padding: 50px 50px 30px 80px;\n}\n.footer-dl dt[data-v-7ba5bd90] {\n    width: 100%;\n    height: 22px;\n    margin-bottom: 30px;\n    color: #ffffff;\n    font-size: 22px;\n    margin-bottom: 40px;\n}\n.footer-dl dd[data-v-7ba5bd90] {\n    width: 100%;\n    height: 30px;\n    color: #ffffff;\n    font-size: 14px;\n}\n.footer-dl dd a[data-v-7ba5bd90] {\n    color: #ffffff;\n}\n.footer-dl-phone[data-v-7ba5bd90] {\n    width: 308px;\n    height: 36px;\n    font-size: 18px;\n    line-height: 36px;\n    text-indent: 20px;\n    margin-bottom: 20px;\n    background-color: #ffffff;\n    opacity: 0.2;\n}\n.footer-dl-phone span[data-v-7ba5bd90] {\n    color: black;\n}\n", ""]);
// Exports
module.exports = exports;
