<!--
* @FileDescription: 页面布局模版
* @Author: jck
* @Date: 2020-8-30
* @LastEditors: jck
* @LastEditTime: 2020-8-30
-->
<template>
    <div :style="{ minWidth: '1250px' }">
        <a-layout>
            <a-layout-header
                :style="{ position: 'fixed', zIndex: 1, width: '100%', height: '64px', boxShadow: '0px 2px 10px 0px rgb(175 175 175 / 50%)' }"
            >
                <div class="logo" @click="toHomePage">
                    <!-- <img src="@/assets/logo.png" /> -->
                </div>
                <!-- <div class="seach" >
                    <a-input-search style="width: 200px" />
                </div> -->
                <a-menu theme="light" mode="horizontal" v-model="current" :style="{ lineHeight: '62px' }">
                    <a-sub-menu>
                        <span slot="title">产品服务</span>
                        <a-menu-item key="setting:1">
                            <router-link to="/product/cheer-maas">CheerMaaS</router-link>
                        </a-menu-item>
                        <a-menu-item key="setting:2">
                            <router-link to="/product/cheer-vm">CheerVM</router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu>
                        <span slot="title">经典案例</span>
                        <a-menu-item key="某大型股份制银行">
                            <router-link :to="{ path: '/casePage', query: { index: 0 } }">某大型股份制银行</router-link>
                        </a-menu-item>
                        <a-menu-item key="某大型证劵A">
                            <router-link :to="{ path: '/casePage', query: { index: 1 } }">某大型证劵A</router-link>
                        </a-menu-item>
                        <a-menu-item key="某大型证劵B">
                            <router-link :to="{ path: '/casePage', query: { index: 2 } }">某大型证劵B</router-link>
                        </a-menu-item>
                        <a-menu-item key="某信托登记机构">
                            <router-link :to="{ path: '/casePage', query: { index: 3 } }">某信托登记机构</router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu>
                        <span slot="title">解决方案</span>
                        <a-menu-item key="金融行业解决方案">
                            <router-link to="/solution/finance">金融行业解决方案</router-link>
                        </a-menu-item>
                        <a-menu-item key="能源行业解决方案">
                            <router-link to="/solution/energy">能源行业解决方案</router-link>
                        </a-menu-item>
                        <a-menu-item key="政企行业解决方案">
                            <router-link to="/solution/government">政企行业解决方案</router-link>
                        </a-menu-item>
                        <!-- <a-menu-item key="安全国产化解决方案">
                            <router-link to="/solution/security" >安全国产化解决方案</router-link>
                        </a-menu-item>
                        <a-menu-item key="园区网解决方案">
                            <router-link to="/solution/park-network" >园区网解决方案</router-link>
                        </a-menu-item>
                        <a-menu-item key="负载均衡解决方案">
                            <router-link to="/solution/load-balancing" >负载均衡解决方案</router-link>
                        </a-menu-item>
                        <a-menu-item key="数据中心解决方案">
                            <router-link to="/solution/data-center" >数据中心解决方案</router-link>
                        </a-menu-item>
                        <a-menu-item key="现场服务解决方案">
                            <router-link to="/solution/field-service" >现场服务解决方案</router-link>
                        </a-menu-item> -->
                    </a-sub-menu>
                    <a-sub-menu>
                        <span slot="title">关于智迩</span>
                        <a-menu-item key="关于我们">
                            <router-link to="/aboutus">关于我们</router-link>
                        </a-menu-item>
                        <a-menu-item key="荣誉资质">
                            <router-link to="/honor">荣誉资质</router-link>
                        </a-menu-item>
                        <a-menu-item key="企业文化">
                            <router-link to="/culture">企业文化</router-link>
                        </a-menu-item>
                        <a-menu-item key="公司动态">
                            <router-link to="/dynamic">公司动态</router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item key="5">
                        <router-link to="/contactus">联系我们</router-link>
                    </a-menu-item>
                    <a-menu-item key="6">
                        <router-link to="/joinus">招贤纳士</router-link>
                    </a-menu-item>
                </a-menu>
            </a-layout-header>
            <a-layout-content :style="{ marginTop: '65px' }">
                <div :style="{ background: '#fff', minHeight: '680px', paddingBottom: '80px' }">
                    <router-view />
                </div>
            </a-layout-content>
            <div class="footer">
                <a-row :gutter="24">
                    <a-col :span="3">
                        <dl class="footer-dl">
                            <dt>产品服务</dt>
                            <dd><a href="/product/cheer-maas">CheerMaaS</a></dd>
                            <dd><a href="/product/cheer-vm">CheerVM</a></dd>
                        </dl>
                    </a-col>
                    <a-col :span="3">
                        <dl class="footer-dl">
                            <dt>解决方案</dt>
                            <dd><a href="/solution/finance">金融行业解决方案</a></dd>
                            <dd><a href="/solution/energy">能源行业解决方案</a></dd>
                            <dd><a href="/solution/government">政企行业解决方案</a></dd>
                        </dl>
                    </a-col>
                    <a-col :span="3">
                        <dl class="footer-dl">
                            <dt>关于智迩</dt>
                            <dd><a href="/aboutus">关于我们</a></dd>
                            <dd><a href="/honor">荣誉资质</a></dd>
                            <dd><a href="/culture">企业文化</a></dd>
                            <dd><a href="/dynamic">公司动态</a></dd>
                        </dl>
                    </a-col>
                    <a-col :span="3">
                        <dl class="footer-dl">
                            <dt>联系我们</dt>
                            <dd><a href="/contactus">联系我们</a></dd>
                        </dl>
                    </a-col>
                    <a-col :span="3">
                        <dl class="footer-dl">
                            <dt>招贤纳士</dt>
                            <dd><a href="/joinus">招贤纳士</a></dd>
                        </dl>
                    </a-col>
                    <a-col :span="1"></a-col>
                    <a-col :span="6">
                        <dl class="footer-dl">
                            <dd>咨询热线</dd>
                            <div class="footer-dl-phone">
                                <span>021- 50802842</span>
                            </div>
                            <dd>咨询邮箱</dd>
                            <div class="footer-dl-phone">
                                <span>cheertech@dataant.com.cn</span>
                            </div>
                        </dl>
                    </a-col>
                </a-row>
                <!--
                <div>
                    <center>Copyright © 2019-2020 上海智迩智能科技有限公司 版权所有</center>
                </div> 
                -->
                <div>
                    <center><a href="https://beian.miit.gov.cn">ICP备案号:沪ICP备2022016805号-1</a></center>
                </div>
            </div>
        </a-layout>
    </div>
</template>

<script>
import Router from '@/router/index.js'

export default {
    data() {
        return {
            current: []
        }
    },
    created() {
        document.title = 'CheerTech'
    },
    methods: {
        // 前往首页
        toHomePage() {
            if (this.$route.path !== '/') {
                this.current = []
                Router.push('/')
            }
        }
    }
}
</script>

<style scoped>
/* 自定义颜色进度条 */
#nprogress .bar {
    background: #409eff !important;
}
/* 修改默认菜单文字大小 */
.ant-menu {
    font-size: 18px;
}
/* 修改默认菜单 */
.ant-menu-horizontal {
    border-bottom: 0;
}
/* 顶部导航栏 */
.ant-layout-header {
    background: #ffffff;
}
/* logo图片 等比例缩放 */
.logo {
    width: 120px;
    height: 40px;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    background-image: url('./assets/logo.png');
    background-size: 100% 100%;
    cursor: pointer;
    float: left;
}
.logo img {
    width: 100%;
    height: 100%;
}
/* 顶部搜索框 */
.seach {
    float: right;
    margin-right: 3rem;
}
/* 底部导航栏 */
.footer {
    background: #409eff;
    padding: 50px 50px 30px 80px;
}
.footer-dl dt {
    width: 100%;
    height: 22px;
    margin-bottom: 30px;
    color: #ffffff;
    font-size: 22px;
    margin-bottom: 40px;
}
.footer-dl dd {
    width: 100%;
    height: 30px;
    color: #ffffff;
    font-size: 14px;
}
.footer-dl dd a {
    color: #ffffff;
}
.footer-dl-phone {
    width: 308px;
    height: 36px;
    font-size: 18px;
    line-height: 36px;
    text-indent: 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    opacity: 0.2;
}
.footer-dl-phone span {
    color: black;
}
</style>
