var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { minWidth: "1250px" } },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout-header",
            {
              style: {
                position: "fixed",
                zIndex: 1,
                width: "100%",
                height: "64px",
                boxShadow: "0px 2px 10px 0px rgb(175 175 175 / 50%)",
              },
            },
            [
              _c("div", { staticClass: "logo", on: { click: _vm.toHomePage } }),
              _c(
                "a-menu",
                {
                  style: { lineHeight: "62px" },
                  attrs: { theme: "light", mode: "horizontal" },
                  model: {
                    value: _vm.current,
                    callback: function ($$v) {
                      _vm.current = $$v
                    },
                    expression: "current",
                  },
                },
                [
                  _c(
                    "a-sub-menu",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("产品服务"),
                      ]),
                      _c(
                        "a-menu-item",
                        { key: "setting:1" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/product/cheer-maas" } },
                            [_vm._v("CheerMaaS")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "setting:2" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/product/cheer-vm" } },
                            [_vm._v("CheerVM")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-sub-menu",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("经典案例"),
                      ]),
                      _c(
                        "a-menu-item",
                        { key: "某大型股份制银行" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { path: "/casePage", query: { index: 0 } },
                              },
                            },
                            [_vm._v("某大型股份制银行")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "某大型证劵A" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { path: "/casePage", query: { index: 1 } },
                              },
                            },
                            [_vm._v("某大型证劵A")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "某大型证劵B" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { path: "/casePage", query: { index: 2 } },
                              },
                            },
                            [_vm._v("某大型证劵B")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "某信托登记机构" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { path: "/casePage", query: { index: 3 } },
                              },
                            },
                            [_vm._v("某信托登记机构")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-sub-menu",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("解决方案"),
                      ]),
                      _c(
                        "a-menu-item",
                        { key: "金融行业解决方案" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/solution/finance" } },
                            [_vm._v("金融行业解决方案")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "能源行业解决方案" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/solution/energy" } },
                            [_vm._v("能源行业解决方案")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "政企行业解决方案" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/solution/government" } },
                            [_vm._v("政企行业解决方案")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-sub-menu",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("关于智迩"),
                      ]),
                      _c(
                        "a-menu-item",
                        { key: "关于我们" },
                        [
                          _c("router-link", { attrs: { to: "/aboutus" } }, [
                            _vm._v("关于我们"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "荣誉资质" },
                        [
                          _c("router-link", { attrs: { to: "/honor" } }, [
                            _vm._v("荣誉资质"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "企业文化" },
                        [
                          _c("router-link", { attrs: { to: "/culture" } }, [
                            _vm._v("企业文化"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "公司动态" },
                        [
                          _c("router-link", { attrs: { to: "/dynamic" } }, [
                            _vm._v("公司动态"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "5" },
                    [
                      _c("router-link", { attrs: { to: "/contactus" } }, [
                        _vm._v("联系我们"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "6" },
                    [
                      _c("router-link", { attrs: { to: "/joinus" } }, [
                        _vm._v("招贤纳士"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-layout-content", { style: { marginTop: "65px" } }, [
            _c(
              "div",
              {
                style: {
                  background: "#fff",
                  minHeight: "680px",
                  paddingBottom: "80px",
                },
              },
              [_c("router-view")],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c("a-col", { attrs: { span: 3 } }, [
                    _c("dl", { staticClass: "footer-dl" }, [
                      _c("dt", [_vm._v("产品服务")]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/product/cheer-maas" } }, [
                          _vm._v("CheerMaaS"),
                        ]),
                      ]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/product/cheer-vm" } }, [
                          _vm._v("CheerVM"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 3 } }, [
                    _c("dl", { staticClass: "footer-dl" }, [
                      _c("dt", [_vm._v("解决方案")]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/solution/finance" } }, [
                          _vm._v("金融行业解决方案"),
                        ]),
                      ]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/solution/energy" } }, [
                          _vm._v("能源行业解决方案"),
                        ]),
                      ]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/solution/government" } }, [
                          _vm._v("政企行业解决方案"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 3 } }, [
                    _c("dl", { staticClass: "footer-dl" }, [
                      _c("dt", [_vm._v("关于智迩")]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/aboutus" } }, [
                          _vm._v("关于我们"),
                        ]),
                      ]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/honor" } }, [
                          _vm._v("荣誉资质"),
                        ]),
                      ]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/culture" } }, [
                          _vm._v("企业文化"),
                        ]),
                      ]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/dynamic" } }, [
                          _vm._v("公司动态"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 3 } }, [
                    _c("dl", { staticClass: "footer-dl" }, [
                      _c("dt", [_vm._v("联系我们")]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/contactus" } }, [
                          _vm._v("联系我们"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 3 } }, [
                    _c("dl", { staticClass: "footer-dl" }, [
                      _c("dt", [_vm._v("招贤纳士")]),
                      _c("dd", [
                        _c("a", { attrs: { href: "/joinus" } }, [
                          _vm._v("招贤纳士"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 1 } }),
                  _c("a-col", { attrs: { span: 6 } }, [
                    _c("dl", { staticClass: "footer-dl" }, [
                      _c("dd", [_vm._v("咨询热线")]),
                      _c("div", { staticClass: "footer-dl-phone" }, [
                        _c("span", [_vm._v("021- 50802842")]),
                      ]),
                      _c("dd", [_vm._v("咨询邮箱")]),
                      _c("div", { staticClass: "footer-dl-phone" }, [
                        _c("span", [_vm._v("cheertech@dataant.com.cn")]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("center", [
                    _c("a", { attrs: { href: "https://beian.miit.gov.cn" } }, [
                      _vm._v("ICP备案号:沪ICP备2022016805号-1"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }