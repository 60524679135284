import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './utils/antPlugin'
//导入
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
})

//路由跳转后，页面回到顶部
router.afterEach(() => {
    NProgress.done()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
